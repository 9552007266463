import React, { useState } from 'react'
import logo from '../images/Quantics_Logo.svg'
import { LogoWrapper } from '../utils/styles'
import { Link, navigate } from 'gatsby'
import tw, { styled } from 'twin.macro'
import { SmallSolidTealButton } from '../utils/styles'
import Twitter from '../images/Twitter.svg'
import YouTube from '../images/YouTube.svg'
import LinkedIn from '../images/LinkedIn.svg'
import ArrowRight from '../images/Arrow-right.svg'
import Finance from '../images/Finance.svg'
import Inventory from '../images/Inventory-1.svg'
import ShoppingCart from '../images/Shopping-Cart.svg'
import Speed from '../images/Speed.svg'
import Globe from '../images/Globe.svg'
import { LocaleContext } from './layout'
import LocalizedLink from './localized-link'

const HeaderWrapper = styled.div`
  box-shadow: 0px 2px 25px rgba(29, 33, 72, 0.0975863);
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  background: white;
  z-index: 100;
  @media (max-width: 768px) {
    position: fixed;
  }
`
const MobileDropDownWrapper = styled.div`
  position: fixed;
  top: 83px;
  width: 100%;
  background: white;
  z-index: 99;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  .m-menus {
    border-bottom: 1px solid #dee7ed;
  }
  .m-login {
    border-bottom: 1px solid #dee7ed;
  }
`
const SolutionDropDown = styled.div`
  grid-template-columns: 1fr 1fr;
  width: 500px;
  div {
    max-width: 213px;
  }
  ::after {
    background: linear-gradient(45deg, #1d5a80 50%, #1d5a80 51%, #1d5a80 100%);
    content: '';
    height: 10px;
    left: 70px;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 10px;
  }
`
const TranslatorDropDown = styled.div`
  left: 15%;
  ::after {
    background: linear-gradient(45deg, #1d5a80 50%, #1d5a80 51%, #1d5a80 100%);
    content: '';
    height: 10px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 10px;
  }
`
const ServiceDropDown = styled.div`
  left: -46%;
  ::after {
    background: linear-gradient(45deg, #1d5a80 50%, #1d5a80 51%, #1d5a80 100%);
    content: '';
    height: 10px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 10px;
  }
`
const IndustryDropDown = styled.div`
  left: -30%;
  ::after {
    background: linear-gradient(45deg, #1d5a80 50%, #1d5a80 51%, #1d5a80 100%);
    content: '';
    height: 10px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 10px;
  }
`
const HamburgerMenu = styled.div`
  margin-left: auto;

  #nav-icon3 {
    width: 18px;
    height: 16px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    z-index: 1025;
  }
  #nav-icon3 span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #007e9f;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  #nav-icon3 span:nth-of-type(1) {
    top: 0px;
  }

  #nav-icon3 span:nth-of-type(2),
  #nav-icon3 span:nth-of-type(3) {
    top: 8px;
  }

  #nav-icon3 span:nth-of-type(4) {
    top: 16px;
  }

  #nav-icon3.open span:nth-of-type(1) {
    top: 8px;
    width: 0%;
    left: 50%;
  }

  #nav-icon3.open span:nth-of-type(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #nav-icon3.open span:nth-of-type(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #nav-icon3.open span:nth-of-type(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
`
const SocialLink = styled.div`
  padding-top: 2rem;
  width: 24px;
  a {
    img {
      width: 24px;
      height: 24px;
    }
  }
`

const Header = (props) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [showSolution, setShowSolution] = useState(false)
  const [showTranslator, setShowTranslator] = useState(false)
  const [showIndustries, setshowIndustries] = useState(false)
  const [showServices, setShowServices] = useState(false)
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  let uidObject = {}
  if (typeof props.resourceList != 'undefined') {
    props.resourceList.forEach((r) => {
      uidObject[r.uid] =
        r.alternate_languages.length > 0 ? r.alternate_languages[0].uid : ''
    })
  }
  const handleDropdown = () => {
    setShowDropdown(!showDropdown)
  }
  const handleSolutionClick = () => {
    setShowSolution(!showSolution)
  }
  const handleTranslatorClick = () => {
    setShowTranslator(!showTranslator)
  }
  const handleIndustriesClick = () => {
    setshowIndustries(!showIndustries)
  }
  const handleServicesClick = () => {
    setShowServices(!showServices)
  }
  const handleLinkClick = (e, link) => {
    e.preventDefault()
    setShowDropdown(!showDropdown)
    const localizedLink = lang.locale === 'de-at' ? '/de' + link : link
    navigate(localizedLink)
  }
  const handleResourceLink = (link) => {
    if (Object.keys(uidObject).length === 0) {
      return link
    } else {
      const uid = link.substring(link.lastIndexOf('/') + 1, link.length)
      return link.replace(uid, uidObject[uid])
    }
  }
  //need to add locale value to local storage so website won't redirect automatically when visitor choose it
  const handleTranslatorLinkClick = (e, link, locale) => {
    e.preventDefault()
    setShowDropdown(!showDropdown)
    localStorage.setItem('locale', locale)
    if (link === '') {
      navigate('/' + link)
    } else {
      navigate(handleResourceLink(link))
    }
  }
  const handleDesktopTranslatorLinkClick = (e, link, locale) => {
    e.preventDefault()
    localStorage.setItem('locale', locale)
    if (link === '') {
      navigate('/' + link)
    } else {
      navigate(handleResourceLink(link))
    }
  }
  //solution dropdown content and link
  const SolutionDropDownContent = [
    {
      headline: i18n.dropdown.solution.forecasting.headline,
      desc: i18n.dropdown.solution.forecasting.desc,
      icon: ShoppingCart,
      to: 'forecasting',
    },
    {
      headline: i18n.dropdown.solution.planning.headline,
      desc: i18n.dropdown.solution.planning.desc,
      icon: Finance,
      to: 'planning',
    },
    {
      headline: i18n.dropdown.solution.inventory.headline,
      desc: i18n.dropdown.solution.inventory.desc,
      icon: Inventory,
      to: 'inventory',
    },
    {
      headline: i18n.dropdown.solution.integration.headline,
      desc: i18n.dropdown.solution.integration.desc,
      icon: Speed,
      to: 'integration',
    },
  ]
  return (
    <div style={{ display: 'initial' }}>
      <HeaderWrapper>
        <div tw="container flex py-5 md:py-7">
          <LocalizedLink to="/">
            <LogoWrapper>
              <img src={logo} />
            </LogoWrapper>
          </LocalizedLink>
          <nav
            aria-label="primary"
            tw="relative z-20 flex-col flex-grow hidden pb-4 md:pb-0 md:flex md:justify-start md:flex-row">
            <div tw="relative" className="group">
              {/*<LocalizedLink to="/solutions">*/}
                <button tw="flex flex-row items-center w-full px-4 py-4 mt-2 text-navy text-lg text-left md:w-auto md:mt-0 md:ml-4 focus:outline-none inline-flex">
                  <span>{i18n.menu.solutions}</span>
                  <svg
                    tw="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </button>
              {/*</LocalizedLink>*/}
              <div tw="absolute z-10 hidden group-hover:block rounded-lg">
                <div tw="py-6 px-6 bg-ocean shadow-lg rounded-lg">
                  <div tw="text-white px-4 mb-4">
                    <span>{i18n.dropdown.solution.value_proposition}</span>
                  </div>
                  <SolutionDropDown tw="grid gap-1 text-white">
                    {SolutionDropDownContent.map((s) => {
                      return (
                        <LocalizedLink to={`/solutions/${s.to}`}>
                          <div tw="flex whitespace-nowrap py-4 px-4 block hover:bg-air hover:bg-opacity-10 cursor-pointer rounded-lg">
                            <img src={s.icon} tw="w-6 mb-2" />
                            <p tw="text-sm font-bold">{s.headline}</p>
                            <span tw="whitespace-normal font-normal text-xs">
                              {s.desc}
                            </span>
                            <img src={ArrowRight} tw="w-6" />
                          </div>
                        </LocalizedLink>
                      )
                    })}
                  </SolutionDropDown>
                </div>
              </div>
            </div>
            <div tw="relative" className="group">
              <LocalizedLink to="/services">
                <button tw="flex flex-row items-center w-full px-4 py-4 mt-2 text-navy text-lg text-left md:w-auto md:mt-0 md:ml-4 focus:outline-none inline-flex">
                  {i18n.menu.services}
                  <svg
                    tw="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </button>
              </LocalizedLink>
              <ServiceDropDown tw="absolute z-10 hidden group-hover:block">
                <div tw="rounded-lg bg-white shadow-lg bg-ocean text-white text-center">
                  <div tw="px-4 py-1 pt-4">
                    <LocalizedLink to="/services">
                      <div tw="p-3 whitespace-nowrap w-52 hover:bg-air hover:bg-opacity-10 cursor-pointer rounded-lg">
                        {i18n.footer.services}
                      </div>
                    </LocalizedLink>
                  </div>
                  <div tw="px-4 py-1 pb-4">
                    <LocalizedLink to="/partner">
                      <div tw="p-3 whitespace-nowrap w-52 hover:bg-air hover:bg-opacity-10 cursor-pointer rounded-lg">
                        {i18n.footer.partner}
                      </div>
                    </LocalizedLink>
                  </div>
                </div>
              </ServiceDropDown>
            </div>
            <div tw="relative" className="group">
              {/*<LocalizedLink to="/industry/manufacturing">*/}
                <button tw="flex flex-row items-center w-full px-4 py-4 mt-2 text-navy text-lg text-left md:w-auto md:mt-0 md:ml-4 focus:outline-none inline-flex">
                  {i18n.menu.industries}
                  <svg
                    tw="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </button>
              {/*</LocalizedLink>*/}
              <IndustryDropDown tw="absolute z-10 hidden group-hover:block">
                <div tw="rounded-lg bg-white shadow-lg bg-ocean text-white text-center">
                  {/*<div tw="px-4 pt-4 pb-0">*/}
                  {/*  <LocalizedLink to="/industry/manufacturing">*/}
                  {/*    <div tw="p-3 whitespace-nowrap w-52 hover:bg-air hover:bg-opacity-10 cursor-pointer rounded-lg">*/}
                  {/*      {i18n.dropdown.industries.manufacturing}*/}
                  {/*    </div>*/}
                  {/*  </LocalizedLink>*/}
                  {/*</div>*/}
                  <div tw="px-4 py-1 pt-4">
                    <LocalizedLink to="/industry/paper-and-packaging">
                      <div tw="p-3 whitespace-nowrap w-52 hover:bg-air hover:bg-opacity-10 cursor-pointer rounded-lg">
                        {i18n.dropdown.industries.paper_packaging}
                      </div>
                    </LocalizedLink>
                  </div>
                  <div tw="px-4 py-1">
                    <LocalizedLink to="/industry/construction-material">
                      <div tw="p-3 whitespace-nowrap w-52 hover:bg-air hover:bg-opacity-10 cursor-pointer rounded-lg">
                        {i18n.dropdown.industries.construction_material}
                      </div>
                    </LocalizedLink>
                  </div>
                  <div tw="px-4 py-1">
                    <LocalizedLink to="/industry/wood-and-wood-processing">
                      <div tw="p-3 whitespace-nowrap w-52 hover:bg-air hover:bg-opacity-10 cursor-pointer rounded-lg">
                        {i18n.dropdown.industries.wood_processing}
                      </div>
                    </LocalizedLink>
                  </div>
                  <div tw="px-4 py-1">
                    <LocalizedLink to="/industry/food-and-beverage">
                      <div tw="p-3 whitespace-nowrap w-52 hover:bg-air hover:bg-opacity-10 cursor-pointer rounded-lg">
                        {i18n.dropdown.industries.food_beverage}
                      </div>
                    </LocalizedLink>
                  </div>
                  <div tw="px-4 py-1">
                    <LocalizedLink to="/industry/chemicals">
                      <div tw="p-3 whitespace-nowrap w-52 hover:bg-air hover:bg-opacity-10 cursor-pointer rounded-lg">
                        {i18n.dropdown.industries.chemicals}
                      </div>
                    </LocalizedLink>
                  </div>
                  <div tw="px-4 py-1">
                    <LocalizedLink to="/industry/pharma">
                      <div tw="p-3 whitespace-nowrap w-52 hover:bg-air hover:bg-opacity-10 cursor-pointer rounded-lg">
                        {i18n.dropdown.industries.pharma}
                      </div>
                    </LocalizedLink>
                  </div>
                  <div tw="px-4 py-1 pb-4">
                    <LocalizedLink to="/industry/discrete-manufacturing">
                      <div tw="p-3 whitespace-nowrap w-52 hover:bg-air hover:bg-opacity-10 cursor-pointer rounded-lg">
                        {i18n.dropdown.industries.discrete_manufacturing}
                      </div>
                    </LocalizedLink>
                  </div>
                </div>
              </IndustryDropDown>
            </div>
            <div tw="relative">
              <LocalizedLink to="/resources">
                <button tw="flex flex-row items-center w-full px-4 py-4 mt-2 text-navy text-lg text-left md:w-auto md:inline md:mt-0  focus:outline-none">
                  {i18n.menu.resources}
                </button>
              </LocalizedLink>
            </div>
            <div tw="relative">
              <LocalizedLink to="/about-us">
                <button tw="flex flex-row items-center w-full px-4 py-4 mt-2 text-navy text-lg text-left md:w-auto md:inline md:mt-0  focus:outline-none">
                  {i18n.menu.about_us}
                </button>
              </LocalizedLink>
            </div>
          </nav>
          <div tw="relative hidden md:block" className="group">
            {props.locale === 'en-us' ? (
              <Link
                hrefLang="en-us"
                to={props.location.pathname}>
                <button tw="flex flex-row items-center w-full px-4 py-4 mt-2 text-navy text-lg text-left md:w-auto  md:mt-0 focus:outline-none inline-flex">
                  <img src={Globe} tw="w-6 mr-1" />
                  <span>EN</span>
                  <svg
                    tw="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </button>
              </Link>
            ) : (
              <Link
                hrefLang="de-at"
                to={`/de${props.location.pathname.replace('/de', '')}`}>
                <button tw="flex flex-row items-center w-full px-4 py-4 mt-2 text-navy text-lg text-left md:w-auto  md:mt-0 focus:outline-none inline-flex">
                  <img src={Globe} tw="w-6 mr-1" />
                  <span>DE</span>
                  <svg
                    tw="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </button>
              </Link>
            )}
            <TranslatorDropDown tw="absolute z-10 hidden group-hover:block">
              <div tw="px-4 pt-4 pb-4 bg-white shadow-lg bg-ocean rounded">
                {props.locale === 'en-us' ? (
                  <Link hrefLang="de-at">
                    <div
                      tw="text-white text-center px-4 py-3 hover:bg-air hover:bg-opacity-10 cursor-pointer rounded-lg"
                      onClick={(e) =>
                        handleDesktopTranslatorLinkClick(
                          e,
                          `/de${props.location.pathname}`,
                          'de'
                        )
                      }>
                      DE
                    </div>
                  </Link>
                ) : (
                  <Link hrefLang="en-us">
                    <div
                      tw="text-white text-center px-4 py-3 hover:bg-air hover:bg-opacity-10 cursor-pointer rounded-lg"
                      onClick={(e) =>
                        handleDesktopTranslatorLinkClick(
                          e,
                          props.location.pathname.replace('/de', ''),
                          'en'
                        )
                      }>
                      EN
                    </div>
                  </Link>
                )}
              </div>
            </TranslatorDropDown>
          </div>

          <LocalizedLink to="/request-meeting">
            <SmallSolidTealButton tw="hidden md:block">
              {i18n.buttons.meeting}
            </SmallSolidTealButton>
          </LocalizedLink>
          <HamburgerMenu
            onClick={handleDropdown}
            tw="md:hidden flex items-center">
            <div id="nav-icon3" className={showDropdown ? 'open' : ''}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </HamburgerMenu>
        </div>
      </HeaderWrapper>
      {/* mobile dropdown start from here */}
      {showDropdown ? (
        <MobileDropDownWrapper tw="h-screen">
          <div tw="mx-6 pb-4" className="m-menus">
            <div tw="relative" onClick={handleSolutionClick}>
              <Link>
                <button tw="flex justify-between items-center w-full py-4 mt-2 text-navy text-lg text-left md:w-auto md:inline md:mt-0 focus:outline-none">
                  <span>{i18n.menu.solutions}</span>
                  <svg
                    tw="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </button>
              </Link>
            </div>
            {showSolution ? (
              <div tw="py-8 px-6 bg-ocean shadow-lg rounded-lg">
                <div tw="text-white mb-8">
                  <span>{i18n.dropdown.solution.value_proposition}</span>
                </div>
                <div tw="text-white flex flex-col gap-4">
                  {SolutionDropDownContent.map((s) => {
                    return (
                      <Link
                        to={`/solutions/${s.to}`}
                        onClick={(e) =>
                          handleLinkClick(e, `/solutions/${s.to}`)
                        }>
                        <div>
                          <img src={s.icon} tw="w-6 mb-2" />
                          <p tw="text-sm font-bold">{s.headline}</p>
                          <span tw="font-normal text-xs">{s.desc}</span>
                          <img src={ArrowRight} tw="w-6" />
                        </div>
                      </Link>
                    )
                  })}
                </div>
              </div>
            ) : false}
            <div tw="relative" onClick={handleServicesClick}>
              <Link>
                <button tw="flex justify-between items-center w-full py-4 mt-2 text-navy text-lg text-left md:w-auto md:inline md:mt-0 focus:outline-none">
                  {i18n.menu.services}
                  <svg
                    tw="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </button>
              </Link>
            </div>
            {showServices ? (
              <div tw="z-10">
                <div tw="bg-white shadow-lg bg-ocean rounded-lg">
                  <div tw="px-2 py-2">
                    <Link
                      to="/services"
                      onClick={(e) => handleLinkClick(e, '/services')}>
                      <div tw="text-white text-center p-3">
                        {i18n.footer.services}
                      </div>
                    </Link>
                  </div>
                  <div tw="px-2 py-2">
                    <Link
                      to="/partner"
                      onClick={(e) => handleLinkClick(e, '/partner')}>
                      <div tw="text-white text-center p-3">
                        {i18n.footer.partner}
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            ) : false}
            <div tw="relative" onClick={handleIndustriesClick}>
              <Link>
                <button tw="flex justify-between items-center w-full py-4 mt-2 text-navy text-lg text-left md:w-auto md:inline md:mt-0 focus:outline-none">
                  {i18n.menu.industries}
                  <svg
                    tw="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </button>
              </Link>
            </div>
            {showIndustries ? (
              <div tw="z-10">
                <div tw="bg-white shadow-lg bg-ocean rounded-lg">
                  {/*<div tw="px-2 py-2">*/}
                  {/*  <Link*/}
                  {/*    to="/industry/manufacturing"*/}
                  {/*    onClick={(e) => handleLinkClick(e, '/industry/manufacturing')}>*/}
                  {/*    <div tw="text-white text-center p-3">*/}
                  {/*      {i18n.dropdown.industries.manufacturing}*/}
                  {/*    </div>*/}
                  {/*  </Link>*/}
                  {/*</div>*/}
                  <div tw="px-2 py-2">
                    <Link
                      to="/industry/paper-and-packaging"
                      onClick={(e) =>
                        handleLinkClick(e, '/industry/paper-and-packaging')
                      }>
                      <div tw="text-white text-center p-3">
                        {i18n.dropdown.industries.paper_packaging}
                      </div>
                    </Link>
                  </div>
                  <div tw="px-2 py-2">
                    <Link
                        to="/industry/construction-material"
                        onClick={(e) =>
                            handleLinkClick(e, '/industry/construction-material')
                        }>
                      <div tw="text-white text-center p-3">
                        {i18n.dropdown.industries.construction_material}
                      </div>
                    </Link>
                  </div>
                  <div tw="px-2 py-2">
                    <Link
                        to="/industry/wood-and-wood-processing"
                        onClick={(e) =>
                            handleLinkClick(e, '/industry/wood-and-wood-processing')
                        }>
                      <div tw="text-white text-center p-3">
                        {i18n.dropdown.industries.wood_processing}
                      </div>
                    </Link>
                  </div>
                  <div tw="px-2 py-2">
                    <Link
                        to="/industry/food-and-beverage"
                        onClick={(e) =>
                            handleLinkClick(e, '/industry/food-and-beverage')
                        }>
                      <div tw="text-white text-center p-3">
                        {i18n.dropdown.industries.food_beverage}
                      </div>
                    </Link>
                  </div>
                  <div tw="px-2 py-2">
                    <Link
                        to="/industry/chemicals"
                        onClick={(e) =>
                            handleLinkClick(e, '/industry/chemicals')
                        }>
                      <div tw="text-white text-center p-3">
                        {i18n.dropdown.industries.chemicals}
                      </div>
                    </Link>
                  </div>
                  <div tw="px-2 py-2">
                    <Link
                        to="/industry/pharma"
                        onClick={(e) =>
                            handleLinkClick(e, '/industry/pharma')
                        }>
                      <div tw="text-white text-center p-3">
                        {i18n.dropdown.industries.pharma}
                      </div>
                    </Link>
                  </div>
                  <div tw="px-2 py-2">
                    <Link
                        to="/industry/discrete-manufacturing"
                        onClick={(e) =>
                            handleLinkClick(e, '/industry/discrete-manufacturing')
                        }>
                      <div tw="text-white text-center p-3">
                        {i18n.dropdown.industries.discrete_manufacturing}
                      </div>
                    </Link>
                  </div>

                </div>
              </div>
            ) : false}
            <div tw="relative">
              <Link
                to="/resources"
                onClick={(e) => handleLinkClick(e, '/resources')}>
                <button tw="flex flex-row items-center w-full py-3 mt-2 text-navy text-lg text-left md:w-auto md:inline md:mt-0  focus:outline-none">
                  {i18n.menu.resources}
                </button>
              </Link>
            </div>
            <div tw="relative">
              <Link
                to="/about-us"
                onClick={(e) => handleLinkClick(e, '/about-us')}>
                <button tw="flex flex-row items-center w-full py-3 mt-2 text-navy text-lg text-left md:w-auto md:inline md:mt-0  focus:outline-none">
                  {i18n.menu.about_us}
                </button>
              </Link>
            </div>
            <Link
              to="/request-meeting"
              onClick={(e) => handleLinkClick(e, '/request-meeting')}>
              <SmallSolidTealButton tw="my-4 w-full">
                {i18n.buttons.meeting}
              </SmallSolidTealButton>
            </Link>
          </div>
          <div tw="mx-6">
            <div tw="relative" className="group">
              {props.locale === 'en-us' ? (
                <Link
                  hrefLang="en-us"
                  to={props.location.pathname}
                  onClick={handleTranslatorClick}>
                  <button tw="flex justify-between items-center w-full py-4 mt-2 text-navy text-lg text-left md:w-auto  md:mt-0 md:mr-6 focus:outline-none inline-flex">
                    <div tw="flex flex-row">
                      <img src={Globe} tw="w-6 mr-1" />
                      <span>EN</span>
                    </div>
                    <svg
                      tw="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </button>
                </Link>
              ) : (
                <Link
                  hrefLang="de-at"
                  to={`/de${props.location.pathname.replace('/de', '')}`}
                  onClick={handleTranslatorClick}>
                  <button tw="flex justify-between items-center w-full py-4 mt-2 text-navy text-lg text-left md:w-auto  md:mt-0 md:mr-6 focus:outline-none inline-flex">
                    <div tw="flex flex-row">
                      <img src={Globe} tw="w-6 mr-1" />
                      <span>DE</span>
                    </div>
                    <svg
                      tw="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </button>
                </Link>
              )}
              {showTranslator ? (
                <div tw="z-10">
                  <div tw="px-2 py-2 bg-white shadow-lg bg-ocean rounded-lg">
                    {props.locale === 'en-us' ? (
                      <Link hrefLang="de-at">
                        <div
                          tw="text-white text-center p-3"
                          onClick={(e) =>
                            handleTranslatorLinkClick(
                              e,
                              `/de${props.location.pathname}`,
                              'de'
                            )
                          }>
                          DE
                        </div>
                      </Link>
                    ) : (
                      <Link hrefLang="en-us">
                        <div
                          tw="text-white text-center p-3"
                          onClick={(e) =>
                            handleTranslatorLinkClick(
                              e,
                              props.location.pathname.replace('/de', ''),
                              'en'
                            )
                          }>
                          EN
                        </div>
                      </Link>
                    )}
                  </div>
                </div>
              ) : false}
            </div>
          </div>

          <div tw="flex gap-11 justify-items-center justify-center">
            <SocialLink>
              <Link>
                <img src={LinkedIn} />
              </Link>
            </SocialLink>
            <SocialLink>
              <Link>
                <img src={YouTube} />
              </Link>
            </SocialLink>
            <SocialLink>
              <Link>
                <img src={Twitter} />
              </Link>
            </SocialLink>
          </div>
        </MobileDropDownWrapper>
      ) : false}
    </div>
  )
}
export default Header
